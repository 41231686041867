import React from 'react'
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from 'react-router-dom'

import Homepage from './Homepage'
// import AppleAppSiteAssociation from './WellKnown/apple-app-site-association'
// import AssetsLinks from './WellKnown/assetlinks.json'
// import HTAccess from './htaccess/inedx'

const AppRoutes = () => {
  return (
    <Router>
      <Routes>
        <Route
          path="/*"
          // render={(params) => {
          //   console.log(params)
          //   return null
          // }}
          Component={() => {
            const location = useLocation()
            console.log(location)
            if (
              ![
                ('/.well-known/apple-app-site-association',
                '/.well-known/assetlinks.json'),
              ].includes(location.pathname)
            ) {
              return <Homepage />
            }
            return null
          }}
        />
        {/* <Route
          path="/.well-known/apple-app-site-association"
          Component={AppleAppSiteAssociation}
        /> */}
        {/* <Route path="/.well-known/assetlinks.json" Component={AssetsLinks} /> */}
        {/* <Route path="/.htaccess" Component={HTAccess} /> */}
      </Routes>
    </Router>
  )
}

export default AppRoutes
