import React, { useEffect } from 'react'

const App = () => {
  useEffect(() => {
    ;(function () {
      var app = {
        launchApp: function () {
          window.location.replace('myapp://')
          this.timer = setTimeout(this.openWebApp, 1000)
        },

        openWebApp: function () {
          const iOS =
            !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)

          if (iOS) {
            window.location.replace(
              'https://apps.apple.com/in/app/mytatva/id1590299281',
            )
          } else {
            window.location.replace(
              'https://play.google.com/store/apps/details?id=com.mytatva.patient',
            )
          }
        },
      }

      app.launchApp()
    })()
  }, [])

  return (
    <div>
      <h1>dl.mytatva.in</h1>
    </div>
  )
}

export default App
